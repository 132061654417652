import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import NavigationMenu from "./navmenu"
import logo from "../images/logo.png"
import { Container, Navbar } from "react-bootstrap"

const Header = ({ siteTitle }) => (
  <Navbar
    collapseOnSelect
    expand="lg"
    className="position-absolute px-4 pt-3 vw-100"
    as="header"
    variant="dark"
  >
    <Container fluid>
      <Navbar.Brand className="mr-auto">
        <Link to="/">
          <img
            src={logo}
            width="160"
            height="120"
            className="d-inline-block align-top image-to-white"
            alt={siteTitle}
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <NavigationMenu />
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
