import { Link, Img } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.png"
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
} from "react-bootstrap"

const Footer = ({ siteTitle }) => (
<footer className="chaltenet-footer text-white text-center">
© {new Date().getFullYear()}, ChalteNet - El Chaltén, Santa Cruz, Argentina
{/* {` `}
<a href="https://www.gatsbyjs.org">Gatsby</a> */}
</footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
